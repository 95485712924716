var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"officalItem"},[_c('div',{staticClass:"listCover"},[_c('img',{attrs:{"src":_vm.officialListDetailItem.coverImgUrl || _vm.cover,"alt":""},on:{"click":function($event){_vm.officialListDetailItem.coverImgUrl
            ? _vm.clickCheckAll(_vm.officialListDetailItem.id)
            : ''}}})]),_c('div',{staticClass:"listContainer"},[_vm._t("header"),_c('table',_vm._l(((_vm.officialListDetailItem.tracks &&
            _vm.officialListDetailItem.tracks.slice(0, 5)) ||
          (_vm.officialListDetailItem.topSongs &&
            (_vm.officialListDetailItem.isOpen
              ? _vm.officialListDetailItem.topSongs
              : _vm.officialListDetailItem.topSongs.slice(0, 10))) ||
          _vm.officialListDetailItem.songs.slice(0, 10)),function(item,index){return _c('tr',{key:index,staticClass:"listRows",on:{"click":_vm.handleRowClick,"dblclick":function($event){return _vm.handleRowDbClick(_vm.officialListDetailItem.id || item.id, index)}}},[_c('td',{staticClass:"index"},[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"musicName"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"singer"},[_vm._v(_vm._s(item.ar[0].name))])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCheckAllShow),expression:"isCheckAllShow"}],staticClass:"checkAll",on:{"click":function($event){return _vm.clickCheckAll(_vm.officialListDetailItem.id)}}},[_vm._v(" 查看全部 "),_c('i',{staticClass:"iconfont icon-arrow-right-bold"})])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }